
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';
import TextInput from 'Control/TextInput.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import WalletsApi from 'Apis/Wallets';
import CreateBankRequisitesRequestData from 'Lib/entities/walletExecutor/CreateBankRequisitesRequestData';
import ApiError from 'Entities/ApiError';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import CountryInput from 'Control/CountryInput.vue';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Asset, { IAsset } from 'Entities/publicPresenter/Asset';

interface Data {
    isUserAgree: boolean;
    hasCheckboxError: boolean;
    showTooltip: boolean;
    previousRoute: string;
    step: 1 | 2 | 3;
    variant: null | string;
    activeBeneficiaryNameIndex: number;
    activeAssetIndex: number;
    activeGatewayIndex: number;
    assetsList: Asset[];
    formData: {
        alias: string;
        beneficiaryAddress: string;
        beneficiaryBankAddress: string;
        beneficiaryBankCity: string;
        beneficiaryBankCountry: string;
        beneficiaryBankCountryCode: string;
        beneficiaryBankName: string;
        beneficiaryBankPostalCode: string;
        beneficiaryCity: string;
        beneficiaryCountry: string;
        beneficiaryIban: string;
        beneficiaryPostalCode: string;
        beneficiarySwift: string;
        beneficiaryCountryCode: string;
        beneficiaryAccountNumber: string;
    },
    errors: {
        alias: boolean;
        beneficiaryAddress: boolean;
        beneficiaryBankAddress: boolean;
        beneficiaryBankCity: boolean;
        beneficiaryBankName: boolean;
        beneficiaryBankPostalCode: boolean;
        beneficiaryCity: boolean;
        beneficiaryIban: boolean;
        beneficiaryPostalCode: boolean;
        beneficiarySwift: boolean;
        beneficiaryAccountNumber: boolean;
        isUserAgree: boolean;
    };
}

interface Methods {
    addRequisite: () => void;
    next: () => void;
    nextStep: () => void;
    openLink: (data: string) => void;
    back: () => void;
    handleBeneficiaryCountry: (data: string) => void;
    handleBeneficiaryBankCountry: (data: string) => void;
}

interface Computed {
    currentUser: InternalUserResponse | null;
    isKycVerified: boolean;
    validBeneficiaryNames: string[];
    gatewaysList: string[];
}

export default Vue.extend<Data, Methods, Computed>({
    components: { CountryInput, AssetsBlockchainsDropdown, TextInput, Checkbox, Button, Icon },
    data() {
        return {
            isUserAgree: false,
            hasCheckboxError: false,
            showTooltip: true,
            previousRoute: '/wallets',
            step: 1,
            activeAssetIndex: 0,
            activeGatewayIndex: 0,
            assetsList: [],
            variant: null,
            activeBeneficiaryNameIndex: 0,
            formData: {
                alias: '',
                beneficiaryAddress: '',
                beneficiaryBankAddress: '',
                beneficiaryBankCity: '',
                beneficiaryBankCountry: '',
                beneficiaryBankCountryCode: '',
                beneficiaryBankName: '',
                beneficiaryBankPostalCode: '',
                beneficiaryCity: '',
                beneficiaryCountry: '',
                beneficiaryIban: '',
                beneficiaryPostalCode: '',
                beneficiarySwift: '',
                beneficiaryCountryCode: '',
                beneficiaryAccountNumber: '',
            },
            errors: {
                alias: false,
                beneficiaryAddress: false,
                beneficiaryBankAddress: false,
                beneficiaryBankCity: false,
                beneficiaryBankName: false,
                beneficiaryBankPostalCode: false,
                beneficiaryCity: false,
                beneficiaryIban: false,
                beneficiaryPostalCode: false,
                beneficiarySwift: false,
                beneficiaryAccountNumber: false,
                isUserAgree: false,
            },
        };
    },
    validations() {
        return {
            formData: {
                alias: {
                    required,
                },
                beneficiaryAddress: {
                    required,
                },
                beneficiaryBankAddress: {
                    required,
                },
                beneficiaryBankCity: {
                    required,
                },
                beneficiaryBankName: {
                    required,
                },
                beneficiaryBankPostalCode: {
                    required,
                },
                beneficiaryCity: {
                    required,
                },
                beneficiaryIban: {
                    required,
                },
                beneficiaryPostalCode: {
                    required,
                },
                beneficiarySwift: {
                    required,
                },
                beneficiaryAccountNumber: {
                    required,
                },
            },
        };
    },
    computed: {
        gatewaysList() {
            if (!this.assetsList[this.activeAssetIndex]) {
                return [];
            }
            return this.assetsList[this.activeAssetIndex].transferDetails?.reduce((accum, { blockchainName }) => {
                if (blockchainName && accum.indexOf(blockchainName) === -1) {
                    accum.push(blockchainName);
                }
                return accum;
            }, [] as string[]) ?? [];
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        validBeneficiaryNames() {
            return this.currentUser?.possibleNames ?? [];
        },
    },
    methods: {
        handleBeneficiaryCountry(data) {
            this.formData.beneficiaryCountry = data;
            this.formData.beneficiaryCountryCode = data;
        },
        handleBeneficiaryBankCountry(data) {
            this.formData.beneficiaryBankCountry = data;
            this.formData.beneficiaryBankCountryCode = data;
        },
        back() {
            if (this.step === 1) {
                this.$router.push(this.previousRoute);
                return;
            }
            this.step -= 1;
        },
        openLink(link) {
            window.open(link);
        },
        async next() {
            if (this.step === 3) {
                await this.addRequisite();
            } else {
                this.nextStep();
            }
        },
        nextStep() {
            this.$v.$touch();
            if (this.step === 1) {
                if (!this.$v.formData.alias.$invalid) {
                    this.step += 1;
                } else {
                    this.errors.alias = true;
                }
            } else if (this.step === 2) {
                if (
                    !this.$v.formData.beneficiaryAddress.$invalid
                    && !this.$v.formData.beneficiaryCity.$invalid
                    && !this.$v.formData.beneficiaryPostalCode.$invalid
                    && !this.$v.formData.beneficiaryAccountNumber.$invalid
                ) {
                    this.step += 1;
                } else {
                    if (this.$v.formData.beneficiaryAddress.$invalid) {
                        this.errors.beneficiaryAddress = true;
                    }
                    if (this.$v.formData.beneficiaryCity.$invalid) {
                        this.errors.beneficiaryCity = true;
                    }
                    if (this.$v.formData.beneficiaryPostalCode.$invalid) {
                        this.errors.beneficiaryPostalCode = true;
                    }
                    if (this.$v.formData.beneficiaryAccountNumber.$invalid) {
                        this.errors.beneficiaryAccountNumber = true;
                    }
                }
            }
        },
        async addRequisite() {
            try {
                this.$v.$touch();
                if (!this.$v.$invalid && this.isUserAgree) {
                    try {
                        const { emailCode, totpCode } = await this.$store.dispatch(
                            'Auth/getMFAToken',
                            {
                                type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP,
                                action: 'SaveBankRequisites',
                                emailTemplateData: {
                                    recipientName: this.validBeneficiaryNames[this.activeBeneficiaryNameIndex],
                                    bankAccountNumber: this.formData.beneficiaryAccountNumber,
                                },
                            },
                        );
                        this.$store.commit(SET_LOADING_ON(undefined));
                        await WalletsApi.saveUserBankRequisites(new CreateBankRequisitesRequestData({
                            ...this.formData,
                            asset: this.assetsList[this.activeAssetIndex] ? this.assetsList[this.activeAssetIndex].symbol : undefined,
                            beneficiaryName: this.validBeneficiaryNames[this.activeBeneficiaryNameIndex],
                            totp: totpCode,
                            emailTotp: emailCode,
                        }));
                        await this.$store.dispatch('Notificator/showSuccessNotification', 'Requisites has been successfully saved');
                        await this.$router.push(this.previousRoute);
                    } catch (error) {
                        if (error instanceof ApiError) {
                            await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                            await this.addRequisite();
                        }
                    } finally {
                        this.$store.commit(SET_LOADING_OFF(undefined));
                    }
                } else {
                    if (this.$v.formData.beneficiaryAddress.$invalid) {
                        this.errors.beneficiaryAddress = true;
                    }
                    if (this.$v.formData.beneficiaryBankAddress.$invalid) {
                        this.errors.beneficiaryBankAddress = true;
                    }
                    if (this.$v.formData.beneficiaryBankCity.$invalid) {
                        this.errors.beneficiaryBankCity = true;
                    }
                    if (this.$v.formData.beneficiaryBankName.$invalid) {
                        this.errors.beneficiaryBankName = true;
                    }
                    if (this.$v.formData.beneficiaryBankPostalCode.$invalid) {
                        this.errors.beneficiaryBankPostalCode = true;
                    }
                    if (this.$v.formData.beneficiaryCity.$invalid) {
                        this.errors.beneficiaryCity = true;
                    }
                    if (this.$v.formData.beneficiaryIban.$invalid) {
                        this.errors.beneficiaryIban = true;
                    }
                    if (this.$v.formData.beneficiaryPostalCode.$invalid) {
                        this.errors.beneficiaryPostalCode = true;
                    }
                    if (this.$v.formData.beneficiarySwift.$invalid) {
                        this.errors.beneficiarySwift = true;
                    }
                    if (this.$v.formData.beneficiaryAccountNumber.$invalid) {
                        this.errors.beneficiaryAccountNumber = true;
                    }
                    if (!this.isUserAgree) {
                        this.errors.isUserAgree = true;
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                }
            }
        },
    },
    mounted() {
        const {
            previousRoute,
            variant,
            assets,
        } = this.$route.query;
        if (assets) {
            this.assetsList = JSON.parse(assets as string).map((a: IAsset) => new Asset(a));
        }
        if (variant && variant === '1') {
            this.variant = '1';
        }
        this.previousRoute = previousRoute as string;
        this.$v.$reset();
    },
    watch: {
        activeAssetIndex() {
            this.activeGatewayIndex = 0;
        },
    },
});
